import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import './career.css';
import {Helmet} from 'react-helmet';
import FooterNew from '../../components/layouts/FooterNew';

//Google Analytics 4 Implementation Sahil
import ReactGA from 'react-ga4'; 
ReactGA.send({ hitType: "pageview", page: "./Careers" });

export default class Careers extends Component {
    render() {
        return (
            <div className="root">
                <Helmet>
                    <title>Careers</title>
                    <meta name="description"
      content="Welcome to Westvindh Games. Creator of Pilli Go and more upcoming futuristic games. Explore the whole new experience of mobile gaming. Join us now."/>
                </Helmet>
                <div className="Header">
                    <img className="headerImg" src={require("../images/headder.jpg")} alt="header"/>
                    
                    <h1 className="Heading">CAREERS</h1>
                    <h1 className="subheading">A WORKPLACE THAT EMPOWERS YOU TO BE THE BEST</h1>
                </div>
                <div className="whatweoffer">
                    <h1 className="headings"> WHAT WE OFFER?</h1>
                    <div className="grayblock">
                        <p className="blocktext">CANDIDATE: WHY WORK WITH YOU?</p>
                        <p className="blocktext">WESTVINDH: WITH ME YOU HAVE THE OPPORTUNITY AND THE RIGHT SET 
                             OF TOOLS, TO BUILD THE VERY BEST YOU CAN GIVE TO THIS WORLD.</p>
                    </div>
                    <h1 className="headings" >GURUGRAM</h1>
                </div>
                <div className="gurugram">
                    <img className="gurugramImg"src={require("../images/gurugram-icom-min.png")} alt="gurugram"/>
                    <ul className="GList">
                        <li className="glistitem"><img src={require("../images/TRINGEL-min.png")} class="triangle-right"/> IT Hub of India</li>
                        <li className="glistitem"><img src={require("../images/TRINGEL-min.png")} class="triangle-right"/> Top Notch Education System</li>
                        <li className="glistitem"><img src={require("../images/TRINGEL-min.png")} class="triangle-right"/> Green Societies and Apartments to Live in</li>
                        <li className="glistitem"><img src={require("../images/TRINGEL-min.png")} class="triangle-right"/> Metro, Uber, International Airport – travel and enjoy your holidays</li>
                    </ul>
                </div>
                <div className="People">
                    <h1 className="headings"> PEOPLE AT WESTVINDH</h1>
                    <p>Highly passionate and talented, we people aim to build mobile games that can entertain millions of people. Individually, we are the best in our skill set and as a team, we make the possibly best to happen.</p>
                </div>
                <div className="openings">
                    <h1 className="headings">OPENINGS</h1>
                    <div  className="openingGrid">
                    <Grid container spacing={10}>
                    <Grid className="gridItem"item xs={12} sm={4}>
                     <div className="job"> 
                        <div id="Upper"  className="Upper">GAME DEVELOPER</div>
                        <div id="Down"className="Down">
                            <h1 className="desHead">UNITY PROGRAMMER</h1>
                            
                        <p className="descriptionList">
                        {/* <li className="deslistItem">2+ years experience</li>
                        <li className="deslistItem">Full-time team member</li> */}
                        &#9724;&#xfe0e;  2+ years experience &#9724;&#xfe0e;  Full-time team member
                        </p>
                        <button className="applydes" onClick={event =>  window.location.href='/Apply'}style={{ marginTop:'24%'}}  variant="contained">
                                  APPLY
                            </button>
                        </div> 
                     </div>
                 </Grid>
                    <Grid className="gridItem"item xs={12} sm={4}> 
                    <div className="job"> 
                    <div className="Upper">3D ARTIST</div>
                    <div className="Down">
                    <h1 className="desHead">3D ARTIST</h1>

                    <p className="descriptionList">
                        {/* <li  className="deslistItem">2+ years experience</li>
                        <li className="deslistItem">Full-time team member</li>
                        <li className="deslistItem">Portfolio is required</li> */}
                            &#9724;&#xfe0e;  2+ years experience &#9724;&#xfe0e;  Full-time team member &#9724;&#xfe0e;  Portfolio is required
                        </p>
                        <button className="applydes" onClick={event =>  window.location.href='/Apply'}style={{marginTop:'24%' }} color="primary" variant="contained">
                                  APPLY
                            </button>
                    </div> 
                     </div>
                    </Grid>
                    <Grid className="gridItem"item xs={12} sm={4}>
                    <div className="job"> 
                         <div className="Upper">VOICE ARTIST</div>
                         <div className="Down">
                         <h1 className="desHead">VOICE ARTIST</h1>
                        <p className="description">
                              If you are an awesome voice actor proficient in any language of the world, drop an email to us. We will be in touch with you shortly.</p>
                         <p className="descriptionList">
                        {/* <li className="deslistItem">5+ years experience</li>
                        <li className="deslistItem">Part-time team member</li>
                        <li className="deslistItem">Portfolio is required</li>
                         */}&#9724;&#xfe0e; 5+ years experience &#9724;&#xfe0e;  Part-time team member &#9724;&#xfe0e;  Portfolio is required
                        </p>
                     
                        <button className="applydes" onClick={event =>  window.location.href='/Apply'}style={{ marginTop: '0.3vw' }}color="primary" variant="contained">
                                  APPLY
                            </button>
                             </div> 
                     </div>
                         </Grid>
                    <Grid className="gridItem"item xs={12} sm={4}>
                    <div className="job"> 
                         <div className="Upper">YOUTUBER</div>
                         <div className="Down">
                         <h1 className="desHead">YOUTUBER</h1>
                             <p className="description">
                        Must have owned an YouTube channel with at least 10k  sub.</p>
                        <button className="applydes" onClick={event =>  window.location.href='/Apply'}style={{ marginTop:'23%' }}color="primary" variant="contained">
                                  APPLY
                            </button>
                             </div> 
                     </div>
                         </Grid>
                    <Grid className="gridItem"item xs={12} sm={4}>
                    <div className="job"> 
                         <div className="Upper">TRANSLATOR</div>
                         <div className="Down">
                         <h1 className="desHead">TRANSLATOR</h1>
                             <p className="description">

                             If you are proficient in Spanish, German, Chinese, Japanese or Korean, drop an email to us. We will be in touch with you shortly.
                             </p>
                         <p className="descriptionList" >
                        {/* <li  className="deslistItem">5+ years experience</li>
                        <li className="deslistItem">Part-time team member</li> */}
                        &#9724;&#xfe0e;  5+ years experience &#9724;&#xfe0e;  Part-time team member 
                        </p>
                        <button className="applydes" onClick={event =>  window.location.href='/Apply'}style={{  marginTop:'0vw' }}color="primary" variant="contained">
                                  APPLY
                            </button>
                             </div> 
                     </div>
                         </Grid>
                    <Grid className="gridItem"item xs={12} sm={4}>
                    <div className="job"> 
                         <div className="Upper">MARKETING</div>
                         <div className="Down">
                             <h1 className= "desHead">JUNIOR MARKETING</h1>
                        <p className="description">
                        Handle SEO and Social Media Channels.
                        </p>
                        <button className="applydes" href='/AppDev' style={{ marginTop:'28%' }} onClick={event =>  window.location.href='/Apply'} color="primary" variant="contained">
                                  APPLY
                            </button>
                             </div> 
                     </div>
                         </Grid>
                    </Grid>
                    </div>
                   
                    <p className="quote">“Happiness is the feeling that the world needs your work”</p>
                </div>
                <FooterNew/>
            </div>
        )
    }
}
