import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import './GameDev.css';
import {Helmet} from 'react-helmet'

import BIRDS from 'vanta/dist/vanta.birds.min'

import * as THREE from 'three';
import { useHistory } from "react-router-dom";
import FooterNew from '../../components/layouts/FooterNew';
//import { Scene } from 'three';

//import zIndex from '@material-ui/core/styles/zIndex';

//Google Analytics 4 Implementation Sahil
import ReactGA from 'react-ga4'; 
ReactGA.send({ hitType: "pageview", page: "./GameDev" });

export default class GameDev extends Component {
  
    constructor() {
        super()
        this.vantaRef = React.createRef()
        this.comingSoon=this.comingSoon.bind(this)
      }
      comingSoon=(e)=>{
          alert("Shoot En Stunt Coming Soon!");
      }
      componentDidMount() {
        this.vantaEffect = BIRDS({
          el: this.vantaRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
          scale: 1.00,
          scaleMobile: 1.00,
          backgroundColor: 0xf4f4f4,
          color1: 0x6cff,
          color2: 0x4d84fc,
          colorMode: "lerp",
          birdSize: 1.40,
          wingSpan: 19.00,
          separation: 100.00,
          alignment: 100.00,
          cohesion: 100.00,
          quantity: 3.00

        })}
        componentWillUnmount() {
            if (this.vantaEffect) 
              this.vantaEffect.destroy()
            }

            routeChange=()=> {
                let path = '/GameDev';
                let history = useHistory();
                history.push(path);
              }
            
            
    render() {
    
        return (
            <div className="rootHomeG"  ref={this.vantaRef}>
                <Helmet>
                    <title>Game Development</title>
                    <meta name="description"
      content="Welcome to Westvindh Games. Creator of Pilli Go and more upcoming futuristic games. Explore the whole new experience of mobile gaming. Join us now."/>
                </Helmet>
                
                <div className="Header">
                <img className="headerImg" src={require("../images/HeaderGameDev.jpg")} alt="header"/>

                </div>
                
                <div className="linkCardsG">
                <Grid container spacing={0}>     
                
                    <Grid item xs={12} sm={6}>
                    <div onClick={event =>  window.location.href='https://play.google.com/store/apps/details?id=com.Westvindh.GardenFlower'} className="gridElementG">
                        <div className="cardImgG">
                            <img className="gameImgG" src={require('../images/Gameicons/Missflorist.png')} alt="gamedev"/>
                          </div>
                            <div className='cardTextG Right'  >
                               {/*  <h1 className="cdlgtxtG">MISS</h1>
                                <h1 className="cdlgtxtG">FLORIST</h1>*/}

                               
                                {/* <h1 className="cdsmtxtG" >...</h1>

                               {/* <button className="gamebt"> <a href="http://westvindh.com"className="cdsmtxtLinks">PILLI GO</a></button> <button className="gamebt" style={{marginBottom:"4%"}}> <a onClick={this.comingSoon.bind(this)} className="cdsmtxtLinks"> SHOOT EN STUNT</a></button> 
                               <h1 className="cdsmtxtG">CHECK OUT OUR GAMES</h1> */}
                            </div>
                        </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                        <div onClick={event =>  window.location.href='https://play.google.com/store/apps/details?id=com.Westvindh.WarHero'} className="gridElementG">
                        <div className="cardImgG">
                            <img className="gameImgG" src={require('../images/Gameicons/Savethequeen.png')} alt="gamedev"/>
                          </div>
                          <div className='cardTextG'style={{paddingLeft:"20%"}}>
                               {/*  <h1 className="cdlgtxtG">RAMAYAN</h1>*/}
                               
                                {/* <h1 className="cdsmtxtG" >...</h1>

                               {/* <button className="gamebt"> <a href="http://westvindh.com"className="cdsmtxtLinks">PILLI GO</a></button> <button className="gamebt" style={{marginBottom:"4%"}}> <a onClick={this.comingSoon.bind(this)} className="cdsmtxtLinks"> SHOOT EN STUNT</a></button> 
                               <h1 className="cdsmtxtG">CHECK OUT OUR GAMES</h1> */}
                            </div>
                        </div>
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                    <div onClick={event =>  window.location.href='https://play.google.com/store/apps/details?id=com.westvindh.colorclean3D'} className="gridElementG">
                        <div className="cardImgG">
                            <img className="gameImgG" src={require('../images/Gameicons/Colorclean3D.png')} alt="gamedev"/>
                          </div>
                            <div className='cardTextG Right'  >
                               {/*  <h1 className="cdlgtxtG">COLOR</h1>
                                <h1 className="cdlgtxtG">CLEAN 3D</h1>*/}

                               
                                {/* <h1 className="cdsmtxtG" >...</h1>

                               {/* <button className="gamebt"> <a href="http://westvindh.com"className="cdsmtxtLinks">PILLI GO</a></button> <button className="gamebt" style={{marginBottom:"4%"}}> <a onClick={this.comingSoon.bind(this)} className="cdsmtxtLinks"> SHOOT EN STUNT</a></button> 
                               <h1 className="cdsmtxtG">CHECK OUT OUR GAMES</h1> */}
                            </div>
                        </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                    <div onClick={event =>  window.location.href='https://play.google.com/store/apps/details?id=com.Westvindh.DrawtoPlayBalls3D'} className="gridElementG">
                        <div className="cardImgG">
                            <img className="gameImgG" src={require('../images/Gameicons/Drawtoplay.png')} alt="gamedev"/>
                          </div>
                            <div className='cardTextG Right'  >
                                {/* <h1 className="cdlgtxtG">DRAW</h1>
                                <h1 className="cdlgtxtG">TO PLAY</h1>*/}

                               
                                {/* <h1 className="cdsmtxtG" >...</h1>

                               {/* <button className="gamebt"> <a href="http://westvindh.com"className="cdsmtxtLinks">PILLI GO</a></button> <button className="gamebt" style={{marginBottom:"4%"}}> <a onClick={this.comingSoon.bind(this)} className="cdsmtxtLinks"> SHOOT EN STUNT</a></button> 
                               <h1 className="cdsmtxtG">CHECK OUT OUR GAMES</h1> */}
                            </div>
                        </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                    <div onClick={event =>  window.location.href='https://play.google.com/store/apps/details?id=com.Westvindh.BreakMyRage'} className="gridElementG">
                        <div className="cardImgG">
                            <img className="gameImgG" src={require('../images/Gameicons/Breakmyrage.png')} alt="gamedev"/>
                          </div>
                            <div className='cardTextG Right'  >
                               {/*} <h1 className="cdlgtxtG">BREAK</h1>
                                <h1 className="cdlgtxtG">MY RAGE</h1>*/}

                               
                                {/* <h1 className="cdsmtxtG" >...</h1>

                               {/* <button className="gamebt"> <a href="http://westvindh.com"className="cdsmtxtLinks">PILLI GO</a></button> <button className="gamebt" style={{marginBottom:"4%"}}> <a onClick={this.comingSoon.bind(this)} className="cdsmtxtLinks"> SHOOT EN STUNT</a></button> 
                               <h1 className="cdsmtxtG">CHECK OUT OUR GAMES</h1> */}
                            </div>
                        </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                    <div onClick={event =>  window.location.href='https://play.google.com/store/apps/details?id=com.build.brickcraft'} className="gridElementG">
                        <div className="cardImgG">
                            <img className="gameImgG" src={require('../images/Gameicons/Brickcraft.png')} alt="gamedev"/>
                          </div>
                            <div className='cardTextG Right'  >
                               {/*  <h1 className="cdlgtxtG">BRICK</h1>
                                <h1 className="cdlgtxtG">CRAFT</h1>*/}

                               
                                {/* <h1 className="cdsmtxtG" >...</h1>

                               {/* <button className="gamebt"> <a href="http://westvindh.com"className="cdsmtxtLinks">PILLI GO</a></button> <button className="gamebt" style={{marginBottom:"4%"}}> <a onClick={this.comingSoon.bind(this)} className="cdsmtxtLinks"> SHOOT EN STUNT</a></button> 
                               <h1 className="cdsmtxtG">CHECK OUT OUR GAMES</h1> */}
                            </div>
                        </div>
                        </Grid>

                     <Grid item xs={12} sm={6}>
                        <div onClick={event =>  window.location.href='https://play.google.com/store/apps/details?id=com.westvindh.WestvindhsCat'} className="gridElementG">
                            {/* <a style={{textDecoration:"none"}} href='/Appdev'> */}
                        <div className="cardImgG">
                          {/*<img className="appImgG"  src={require('../images/Gameicons/Pilli.png')} alt="pilli"/>*/}
                          <img className="gameImgG"  src={require('../images/Gameicons/Pilli.png')} alt="pilli"/>

                           </div>
                           <div className='cardTextG Right'  >
                            {/*<div className='cardTextG'style={{paddingLeft:"20%"}}>*/}
                                {/* <h1 className="cdlgtxtG">PILLI GO</h1>*/}
                                {/* <h1 className="cdmdtxtG">DEVELOPMENT</h1> */}
                                {/* <h1 className="cdsmtxtG">...</h1>

                                <h1 className="cdsmtxtG">BRING MORE CUSTOMERS ROCKET YOUR BUSINESS</h1> */}
                            </div>
                            
                        </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <div  className="gridElementG">
                        <div className="cardImgG">
                            <img className="gameImgG" src={require('../images/Gameicons/Comingsoon.png')} alt="gamedev"/>
                          </div>
                            <div className='cardTextG Right'  >
                              {/*}  <h1 className="cdlgtxtG">MORE</h1>
                                <h1 className="cdlgtxtG">GAMES</h1>*/}

                               
                                {/* <h1 className="cdsmtxtG" >...</h1>

                               {/* <button className="gamebt"> <a href="http://westvindh.com"className="cdsmtxtLinks">PILLI GO</a></button> <button className="gamebt" style={{marginBottom:"4%"}}> <a onClick={this.comingSoon.bind(this)} className="cdsmtxtLinks"> SHOOT EN STUNT</a></button> 
                               <h1 className="cdsmtxtG">CHECK OUT OUR GAMES</h1> */}
                            </div>
                        </div>
                        </Grid>
                        
                </Grid>
                </div>
                <FooterNew />
            </div>
            
        )
    }
}
