import React, { Component, useEffect } from 'react'
import {motion} from 'framer-motion';

import {Helmet} from 'react-helmet';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Aos from "aos";

import TranslateIcon from '@material-ui/icons/Translate';

import FooterNew from '../layouts/FooterNew';
import './Artservices.css';

//Google Analytics 4 Implementation Sahil
import ReactGA from 'react-ga4'; 
ReactGA.send({ hitType: "pageview", page: "./Artservices-EN" });


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: 'column',
    padding:'60px',
    backgroundColor:"#FFFFFF"
  },
  logo:{
  float:"left",
   paddingTop:"40px",
   paddingBottom:"40px",
    
  },
  imgright:{
    maxWidth:"720px",
    minWidth:"200px",
    widht:'90%,',
    maxHeight:"720px",
    
    textAlign:"center",
   
    [theme.breakpoints.down('sm')]: {
      maxWidth:'300px',
      widht:'50%',
    
    },
    [theme.breakpoints.up('md')]: {
      maxWidth:'480px',
      widht:'50%',
      paddingTop:"40px",
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth:'720px',
      widht:'50%',
      paddingTop:"50px",
    },
  },
  sketch:{
    maxWidth:"920px",
    minWidth:"200px",
    widht:'100%,',
    maxHeight:"920px",
   
    [theme.breakpoints.down('sm')]: {
      maxWidth:'300px',
      widht:'50%',    
    },
    [theme.breakpoints.up('md')]: {
      maxWidth:'480px',
      widht:'50%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth:'720px',
      widht:'50%',
    },
  },
  
  details:{
    color:"#FFFFFF",
    backgroundColor:"black",
    padding:"350px 100px 350px 50px",
    maxHeight:'900px',
    [theme.breakpoints.down('sm')]: {
      padding:"50px 50px 50px 50px ",
      textAlign:"left",
    },
    [theme.breakpoints.up('md')]: {
      padding:"250px 50px 250px 50px ",
      textAlign:"left",
    },
    [theme.breakpoints.up('lg')]: {
      padding:"350px 100px 350px 50px",
      textAlign:"left",
    },
  },
  sketchDetail:{
    paddingTop:"30px",
    textAlign:"right",
    [theme.breakpoints.down('sm')]: {
      textAlign:"left",
    },
  },
  buttons:{
    size:"small",
    color:"#FFFFFF",
    marginTop:"100px",
    backgroundColor:"#000000",
    [theme.breakpoints.down('sm')]: {
      marginTop:"10px"
    },
    [theme.breakpoints.up('md')]: {
      marginTop:"100px",
    },  
  },

}));

export default function ArtServices()  {
      const classes = useStyles();
      useEffect(()=>{
        Aos.init({ duration: 1200,
          easing: "ease-in-back-sine",
          delay:800
        });
      },[]);
        return (
          <div>
            <div className={classes.root}>
            <Helmet>
                    <title>Art Services</title>
                    <meta name="description"
       content="Welcome to Westvindh Games. Creator of Pilli Go and more upcoming futuristic games. Explore the whole new experience of mobile gaming. Join us now."/>
                </Helmet>
<div className="LnButtons">
<button onClick={e=>window.location.href="/ArtServices-En"} className="lnbutton" backgroundColor="black" variant="contained" startIcon={<TranslateIcon />}>         
             English
           </button>
       
             <button className="lnbutton" onClick={e=>window.location.href="/ArtServices-Jp"}variant="contained" startIcon={<TranslateIcon />}>
             Japanese
            </button>
</div>
      <Grid  container spacing={8}>     
        {/* <Grid item xs={12} sm={3}>
             <button onClick={e=>window.location.href="/ArtServices"} className="lnbuttons" backgroundColor="black" variant="contained" startIcon={<TranslateIcon />}>         
             English
           </button>
        </Grid>
        <Grid item xs={12} sm={3}>
             <button className="lnbuttons" onClick={e=>window.location.href="/ArtServices"}variant="contained" startIcon={<TranslateIcon />}>
             Japanese
            </button>
        </Grid> */}
        <Grid item xs={12} sm={6}>
        <motion.img whileHover={{ scale:1.1}}  data-aos="fade-right" className="sketch"
           src={require("../images/cover.jpg")} alt=""/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div data-aos="fade-left" className="sketchDetail">
        <Typography>Looking For a</Typography>
               <Typography variant="h4">Character Design?</Typography>
               </div>
        </Grid>
        <Grid item xs={12} sm={6}>
        <div data-aos="fade-right" className="details">
        <Typography>Looking For A</Typography>
               <Typography variant="h4">Character Design ?
                {/* <Particles 
        params={{ 
          particles: { 
            number: { 
              value: 400, 
              density: { 
                enable: true, 
                value_area: 1000, 
              },             
            },          
            color:"#000000"
          }, interactivity:{
            events:{
              onhover:{
                enable:true,
                mode: "repulse"
              }
            }
          }
        }} 
      />   */}
      {/* <ParticlesBg type="lines" bg={true}/> */}
               </Typography>
                      </div>
         </Grid>
        <Grid item xs={12} sm={6}>
        
        <motion.img whileHover={{ scale:1.1}} data-aos="fade-left" 
          className="imgright"
          src={require("../images/1st.jpg")} alt=""/>
        </Grid>

        <Grid item xs={12} sm={6}>
        <motion.img whileHover={{ scale:1.1}} data-aos="fade-right"
          className="imgright" src={require("../images/2nd.jpg")} alt=""/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div data-aos="fade-left" className="details"   >
        <Typography>Looking For A</Typography>
               <Typography variant="h4">Character Design ?</Typography>
               {/* <ParticlesBg type="lines" bg={true}/> */}

               </div>
        </Grid>
    
        <Grid item xs={12} sm={6}>
        <div data-aos="fade-right" className="details" >
        <Typography>Looking For A</Typography>
               <Typography variant="h4">Character Design ?</Typography>
               {/* <ParticlesBg type="lines" bg={true}/> */}

               </div>
         </Grid>
        <Grid item xs={12} sm={6}>
          
        <motion.img whileHover={{ scale:1.1}}  data-aos="fade-left"
          className="imgright" src={require("../images/3rd.jpg")} alt=""/>
       
        </Grid>
        <Grid item xs={12} sm={6}>
          
        <motion.img whileHover={{ scale:1.1}} data-aos="fade-right"
            className="imgright" src={require("../images/4th.jpg")} alt=""/>
         
          </Grid>
          <Grid item xs={12} sm={6}>
        <div  data-aos="fade-left" className="details" >
        <Typography>Looking For A</Typography>
               <Typography variant="h4">Character Design ?</Typography>
               {/* <ParticlesBg type="lines" bg={true}/> */}
               </div>
         </Grid>
         <Grid item xs={12} sm={6}>
        <div data-aos="fade-right" className="details" >
        <Typography>Looking For A</Typography>
               <Typography variant="h4">Character Design ?</Typography>
               {/* <ParticlesBg type="lines" bg={true}/> */}
              
               </div>
             
         </Grid>
        <Grid item xs={12} sm={6}>
    
        <motion.img whileHover={{ scale:1.1}}  data-aos="fade-left"
          className="imgright" src={require("../images/5th.jpg")} alt=""/>
       
        </Grid>
      </Grid>
      
     </div>
     <FooterNew/>

              </div>   
        )
    };

