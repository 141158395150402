import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import './Home.css';
import {Helmet} from 'react-helmet'
import NET from 'vanta/dist/vanta.net.min'
import * as THREE from 'three';
import { useHistory } from "react-router-dom";
import FooterNew from '../../components/layouts/FooterNew';
//import { Scene } from 'three';

//import zIndex from '@material-ui/core/styles/zIndex';

//Google Analytics 4 Implementation Sahil
import ReactGA from 'react-ga4'; 
ReactGA.send({ hitType: "pageview", page: "./Home" });

export default class Home extends Component {
  
    constructor() {
        super()
        this.vantaRef = React.createRef()
        this.comingSoon=this.comingSoon.bind(this)
      }
      comingSoon=(e)=>{
          alert("Shoot En Stunt Coming Soon!");
      }
      componentDidMount() {
        this.vantaEffect = NET({
        el: this.vantaRef.current,
        THREE: THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,
        color: 0x0,
        backgroundColor: 0xf4f4f4,
        points: 1.00,
        maxDistance: 5.00,
        spacing: 20.00,
        zIndex:-100,
        })}
        componentWillUnmount() {
            if (this.vantaEffect) 
              this.vantaEffect.destroy()
            }

            routeChange=()=> {
                let path = '/AppDev';
                let history = useHistory();
                history.push(path);
              }
            
            
    render() {
    
        return (
            <div className="rootHome"  ref={this.vantaRef}>
                <Helmet>
                    <title>Westvindh Games</title>
                    <meta name="description"
      content="Welcome to Westvindh Games. Creator of Pilli Go and more upcoming futuristic games. Explore the whole new experience of mobile gaming. Join us now."/>
                </Helmet>
                <div className="Header">
                <img className="headerImg" src={require("../images/HeaderHome.jpg")} alt="header"/>

                {/*
                <h1 className="bigText">Hi</h1>
                <h1 className="smallText">ARE YOU</h1>
                <h1 className="smallText">SEARCHING FOR</h1>
        */}
                </div>
                <div className="linkCards">
                <Grid container spacing={0}>     

                <Grid item xs={12} sm={6}>
                        <div onClick={event =>  window.location.href='/GameDev'} className="gridElement">
                        <div className="cardImg">
                            <img className="gameImg" src={require('../images/1st.jpg')} alt="gamedev"/>
                          </div>
                            <div className='cardText' >
                                <h1 className="cdlgtxt">GAME</h1>
                                <h1 className="cdmdtxt">DEVELOPMENT</h1>
                                <h1 className="cdsmtxt" >...</h1>

                               <h1 className="cdsmtxt">CHECK OUT OUR GAMES</h1>
                            </div>
                        </div>
                        </Grid>
                       
                       <Grid item xs={12} sm={6}>
                       <div onClick={event =>  window.location.href='https://www.youtube.com/c/PilliGo'} className="gridElement">
                        <div className="cardImg">
                            <img className="portImg" src={require('../images/3-min2.png')} alt="portfolio"/>
                           </div>
                            <div className='cardText' style={{paddingLeft:"20%"}}>
                                <h1 className="cdlgtxt">PILLIGO</h1>
                                <h1 className="cdmdtxt">@YOUTUBE</h1>
                                <h1 className="cdsmtxt">...</h1>

                                <h1 className="cdsmtxt">400 MILLION VIEWS</h1>
                                <h1 className="cdsmtxt">500K SUBSCRIBERS</h1>

                            </div>
                        </div>
                        </Grid>
              {/*
                     <Grid item xs={12} sm={6}>
                        <div onClick={event =>  window.location.href='/newApp'} className="gridElement">
                            
                        <div className="cardImg">
                            <img className="appImg"  src={require('../images/1-min.png')} alt="appdev"/>
                           </div>
                            <div className='cardText'style={{paddingLeft:"20%"}}>
                                <h1 className="cdlgtxt">APP</h1>
                                <h1 className="cdmdtxt">DEVELOPMENT</h1>
                                <h1 className="cdsmtxt">...</h1>

                                <h1 className="cdsmtxt">BRING MORE CUSTOMERS ROCKET YOUR BUSINESS</h1>
                            </div>
                            
                        </div>
                        </Grid>
        */}
        {/*
                        <Grid item xs={12} sm={6}>
                        <div onClick={event =>  window.location.href='/GameDev'} className="gridElement">
                        <div className="cardImg">
                            <img className="gameImg" src={require('../images/4-min.png')} alt="gamedev"/>
                          </div>
                            <div className='cardText' >
                                <h1 className="cdlgtxt">GAME</h1>
                                <h1 className="cdmdtxt">DEVELOPMENT</h1>
                                <h1 className="cdsmtxt" >...</h1>

                               <h1 className="cdsmtxt">CHECK OUT OUR GAMES</h1>
                            </div>
                        </div>
                        </Grid>
    */}
                        {/*
                        <Grid item xs={12} sm={6}>
                        <div onClick={event =>  window.location.href='/ArtServices-En'} className="gridElement">
                            <div className="cardImg">
                            <img className="animImg"src={require('../images/2.png')} alt="animation"/>
                            </div>
                            <div className='cardText' style={{paddingLeft:"22%"}}>
                                <h1 style={{color:'#FF7719'}}className="cdlgtxt lgchartxt">CHARACTER</h1>
                                <h1 style={{color:'#FF7719'}}className="cdmdtxt">ARTIST</h1>
                                <h1 className="cdsmtxt">...</h1>

                                <h1 className="cdsmtxt">ASSET FOR YOUR GAMES</h1>
                            </div>
                        </div>
                        </Grid>

    */}
    {/*
                        <Grid item xs={12} sm={6}>
                        <div className="gridElement">
                        <div className="cardImg">
                            <img className="portImg" src={require('../images/3-min.png')} alt="portfolio"/>
                           </div>
                            <div className='cardText' style={{paddingLeft:"20%"}}>
                                <h1 className="cdlgtxt">OUR</h1>
                                <h1 className="cdmdtxt">PORTFOLIO</h1>
                                <h1 className="cdsmtxt">...</h1>

                                <h1 className="cdsmtxt">COMING SOON</h1>
                                <h1 className="cdsmtxt">JAN 2021</h1>

                            </div>
                        </div>
                        </Grid>
*/}

                 
                </Grid>
                </div>
                <FooterNew />
            </div>
            
        )
    }
}
