import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';

import './index.css';
import App from './App';
import './fonts/Humanist777BoldCondensedBT.ttf';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from 'react-router-dom';
ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

const TRACKING_ID = "G-DRW8T58LHC"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
