import React, {useState} from 'react';
import './App.css';
import Navbar from './components/layouts/Navbar';
import Home from './components/Pages/Home';
import FooterNew from './components/layouts/FooterNew';
//import Animations from './components/Pages/Animations';
//import AppDev from './components/Pages/AppDev';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import QuestionAnswerRoundedIcon from '@material-ui/icons/QuestionAnswerRounded';
import {
  Route, Switch,
  Redirect,
BrowserRouter as Router
} from 'react-router-dom';
import Chatbox from './components/layouts/Chatbox';
import contact from './components/Pages/contact';
import Careers from './components/Pages/Careers';
import Apply from './components/Pages/Apply';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import newApp from './components/Pages/newApp';
// import Trial from './components/Pages/Trial';
import ArtServices from './components/Pages/ArtServices';
import GameDev from './components/Pages/GameDev';

function App() {
  const [show, setshow]=useState(false);
  const openChat=()=>{
  //  this.state({show:true});
      setshow(!show);
  };
 
  return (
    <div className="App">
      <button className="chatbutton" onClick={openChat}>
        GET IN TOUCH
      </button>
      <QuestionAnswerRoundedIcon onClick={openChat} className="chaticon"/>
      <Chatbox show={show} onClose={openChat}/>
      <Router>
      {/* <CssBaseline /> */}
      <Navbar />
      
      <Switch>
      <Route exact path="/">
          <Redirect to="/Home" />
        </Route>

        <Route exact path="/Home" component={Home} />
        {/* <Route exact path="/AppDev" component={AppDev}/>  */}
        <Route exact path="/Careers" component={Careers}/>
        <Route exact path="/ContactUS" component={contact}/>
        <Route exact path="/Apply" component={Apply}/>
        <Route exact path="/PrivacyPolicy" component={PrivacyPolicy}/>
        <Route exact path="/newApp" component={newApp}/>
        {/* <Route exact path="/Trial" component={Trial}/> */}
        <Route exact path="/ArtServices-En" component={ArtServices}/>
        <Route exact path="/ArtServices-Jp" component={ArtServices}/>
        <Route exact path="/GameDev" component={GameDev}/>



        
      </Switch>
      {/* <Chatbox /> */}
      {/* <FooterNew /> */}
      </Router>
    
    </div>
  );
}

export default App;

