import React ,{Component}from 'react';
import ReactDOM from 'react-dom';
import { AppBar,
} from '@material-ui/core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import './navbar.css';
import { withStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';


const useStyles = (theme) => ({
    root: {
    position:"fixed",
      top: '0',
      width: '100%',
      height:'5vw',
      background:"black",
      display: 'flex',
      flexDirection:'row',
      textAlign:"left",
      [theme.breakpoints.down('sm')]: {
        height:'6%',
      },
    },
    links:{
        fontFamily:'Fredoka One',
        fontSize:'100%',
        letterSpacing:'0.2vw',
        color:"#FFFFFF",
        marginLeft:'3vw',
        textDecoration:"none",
        [theme.breakpoints.down('sm')]: {
            
          },
    },
    nav:{
        paddingTop:'1.7vw',
        [theme.breakpoints.down('sm')]: {
            paddingTop:'0',
          },
          [theme.breakpoints.between('xs','sm')]: {
            display:"none",
        },
    },
    logoholder:{
        
        paddingTop:'1.2%',
        [theme.breakpoints.down('sm')]: {
          
          },

    },
    logo:{
        textDecoration:'none',
        height:'4vw',
        marginBottom:'-1vw',
        marginTop:'-0.8vw',
        marginLeft:'2vw',
        [theme.breakpoints.down('sm')]: {
            height:'100%',
          },
    },
})
//  openNav=(e)=>{
//          document.getElementById('SideNav').style.width='100%';
       
//     }
//      closeNav=(e)=>{
//         var y = document.getElementById('SideNav');
//         y.style.width='0%';
//     }
class Navbar extends Component {
    constructor(props){
        super(props);
        this.closeNav = this
        .closeNav
        .bind(this);

    }
    // classes = useStyles();
    openNav=(e)=>{
        // document.getElementById("SideNav").style.width='100%';
       console.log('dsds');
    }
    closeNav=(e)=>{
       // var y = document.getElementById('SideNav');
        //y.style.width='0%';
       console.log('closed');

    }
    render(){
        const {classes} = this.props;
       
        return (
            <div component="nav">
                <AppBar className={classes.root} >
                    <div className="logholder"> 
                    <a href="/Home">
                     <img className="nav-logo"src={require('../images/whitelogo.png')} alt="logo"/>
                             </a>
                              </div>
                 <div className="nav">
                    <a className={classes.links} href="/Home">HOME
                             </a>
                     <a  href="/Careers"
                            className={classes.links} >CAREERS
                     </a>
                     <a  href="/ContactUs"
                            className={classes.links} >CONTACT US
                     </a>
                     {/* <a href="/AppDev" 
                            className={classes.links} >APP DEVELOPMENT
                     </a> */}
                     </div>
                     <MenuRoundedIcon className="menuIcon" onClick={(e)=>{e.preventDefault();document.getElementById("SideNav").style.width='100%';
                    document.getElementById("closeBtn").style.visibility='visible';  }}/>
                    <div id="SideNav"className="sidenav">
                    <CloseRoundedIcon className="closeBtn" id="closeBtn" onClick={(e)=>{e.preventDefault();document.getElementById("SideNav").style.width='0%';
                 document.getElementById("closeBtn").style.visibility='hidden';  }}/>
                            <a className="sidelinks" href="/Home">HOME</a>
                            <a className="sidelinks"  href="/Careers">CAREERS</a>   
                             <a className="sidelinks" href="/ContactUs">CONTACT US</a>
        
                    </div>
                  
                </AppBar>
            </div>
        )
    }
}
export default withStyles(useStyles)(Navbar);
