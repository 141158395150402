import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import './chatbox.css';
import * as emailjs from 'emailjs-com';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Recaptcha from 'react-google-invisible-recaptcha';
const useStyles = theme => ({
    root: {
        display: "flex",
    },
    wrapper:{
        position:'fixed',
        top:'0%',
        right:'2%',
        width:"20%",
        height:"20%",
        zIndex:'1001', 
        border:'',
        background:'transparent',
    },
    chatBox:{
        boxShadow:' 0 15px 25px rgba(0,0,0,.6)', 
        borderBottom:'none',
        height:'100%',
        background:'transparent',
        borderTopLeftRadius:20,
        '&:hover':{
            cursor:'pointer'
        }
    },
    chatHead:{
        fontFamily:'Fredoka One',
        color:'white',
        height:'100px',
        background:'black',
        top:0,
        padding:'10px',
        //borderTopLeftRadius:20,  
    },
    chatFoot:{
        background:"#4B66A9",
        height:'100%',
        paddingTop:'10px',
    },
    chatForm:{
        padding:'10px',
        marginTop:'10px',
    },
    closebt:{
        marginTop:'7%',
        zIndex: 1001,
        background: 'none',
        border:'none',
        "&:hover":{
            border:"2px"
        }
    },
    inputbox:{

    },
    submitted:{
        display:'none',
        background:"#4B66A9",
        height:'90%',
        paddingTop: '20%',
        zIndex:'1002',
        color:'white',
        fontSize:"5vw",
        borderRadius:"20px",
        boxShadow:"0 15px 25px rgba(0,0,0,.6)",
    }
});

class Chatbox extends Component {
 constructor(){
    super();
    this.state={
        fullname:'',
        Email:'',
        number:'',
        message:'',
        
    }
    this.handleSubmit=this.handleSubmit.bind(this)
    this.handleEmailChange=this.handleEmailChange.bind(this)
    this.handleNameChange=this.handleNameChange.bind(this)
    this.handleMessageChange=this.handleMessageChange.bind(this)
}
    handleNameChange = (e) =>{
        this.setState({
         fullname:e.target.value
        })
    }
    handleEmailChange = (e) =>{
        this.setState({
            Email:e.target.value
        })
    }
    handleNumberChange = (e) =>{
        this.setState({
            number:e.target.value
        })
    }
    handleMessageChange = (e) =>{
        this.setState({
            message:e.target.value
        })
    }

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  }
  showClose=(e)=>{
    var x = document.getElementById('submitted');
    x.style.display='block'
    var y = document.getElementById('chatFoot');
    y.style.display='none'
  }
 handleSubmit=(e)=>{
    e.preventDefault()
   const {fullname, Email, number,message } = this.state
   if(fullname.length && Email.length && number.length)
  {  
      emailjs.sendForm(
        "service_tj8ar23",
        "template_m8osba3",
        "ContactForm",
        "user_sovuqMtzC3Tcwwi1dPD5w"
    ).then()
    .catch();
    console.log("email sent !")
    this.setState({
        fullname:'',
        Email:'',
        number:'',
        message:'',
    });
    this.recaptcha.execute();
    this.showClose();
}
    else {
        alert("Validation failed! Input cannot be empty");
        // this.recaptcha.reset();
    }
     

 };
    
    render() {
       // const [close, setclose] = React.useState(false); 
        // const {fullname, email, number}=this.state;
        if(!this.props.show){
            return null;
        } else 
       { const {classes} = this.props;
       
        return (
            <div className="wrapper">
               
                <div className="chatBox">
                    <div id="chatFoot"className="chatFoot">
                        <h1 className="header">GET IN TOUCH</h1>
                    <form id="ContactForm" onSubmit={this.handleSubmit.bind(this)}>
                        <input className="formIn"
                        label="Name" 
                        name="fullname"
                        id="fullname"
                        value={this.state.fullname}
                        type='text' 
                        placeholder="NAME" 
                        onChange={this.handleNameChange.bind(this)} />
                        <input className="formIn"
                        label="Email" 
                        name="Email"
                        id="Email"
                        type="email" 
                        value={this.state.Email} 
                        placeholder="EMAIL" 
                        onChange={this.handleEmailChange.bind(this)}
                        />
                        <input className="formIn"
                       label="Number" 
                       id="number"
                       name="number"
                       type="tel" 
                       value={this.state.number} 
                       placeholder="PHONE NUMBER" 
                       onChange={this.handleNumberChange.bind(this)}
                        />
                        <input className="formIn"
                       label="message" 
                       id="message"
                       name="message"
                       type="text" 
                       value={this.state.message} 
                       placeholder="SUBMIT YOUR REQUIREMENT" 
                       onChange={this.handleMessageChange.bind(this)}
                        />
                 
                    <br></br>
                     <button onClick={this.handleSubmit.bind(this)} type='submit'   className="closebt">
                 SUBMIT
                </button>    
                <button onClick={this.onClose.bind(this)} type='submit'  className="closebt">
                 CLOSE
                </button>   
       </form>     
                </div>
                <div className="submitted" id="submitted">
                    <CheckCircleIcon fontSize="large" style={{textAlign:"center", color: "#FFF" }} />
                    <p className="submittxt">Response Submitted!</p>
                    <button onClick={this.onClose.bind(this)} type='submit'  className="closebt">
                 CLOSE
                </button>
                </div>
                </div> 
                <Recaptcha
          ref={ ref => this.recaptcha = ref }
          sitekey="6LcOGMsZAAAAAE4Stjf3Qhy7ltUiypETLk2pwC3b"
          onResolved={ () => console.log( 'Human detected.' ) }
          />    
            </div>
        )}
    }
}  
export default withStyles(useStyles)(Chatbox);

