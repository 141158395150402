import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import FooterNew from '../layouts/FooterNew';
import './privacy.css'

//Google Analytics 4 Implementation Sahil
import ReactGA from 'react-ga4'; 
ReactGA.send({ hitType: "pageview", page: "./PrivacyPolicy" });


export default class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="privacyroot">
                  <Helmet>
                    <title>Privacy Policy - WestVindh Games</title>
                    <meta name="description"
      content="Welcome to Westvindh Games. Creator of Pilli Go and more upcoming futuristic games. Explore the whole new experience of mobile gaming. Join us now."/>
                </Helmet>
                <div className="headblock">
                <h1 className="privhead">PRIVACY POLICY</h1>
                </div>
               <div className="lowerblock">
                <h1 className="privysubheads">1. Summary & Scope</h1>
                <p className="privpara"></p>
                <h1 className="privysubheads">2. Who we are</h1>
                <p className="privpara"></p>
                <h1 className="privysubheads">3. Companies and websites within scope</h1>
                <p className="privpara"></p>
                <h1 className="privysubheads">4.Collection of Information</h1>
                <p className="privpara"></p>
                <h1 className="privysubheads">5. Our Security Policies</h1>
                <p className="privpara"></p>
                <h1 className="privysubheads">6. Contact us</h1>
                <p className="privpara"></p>
                </div>
                <FooterNew/>
            </div>
        )
    }
}
