
import React, { Component } from 'react'
import {Helmet} from 'react-helmet';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './newApp.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import FooterNew from '../../components/layouts/FooterNew';

//Google Analytics 4 Implementation Sahil
import ReactGA from 'react-ga4'; 
ReactGA.send({ hitType: "pageview", page: "./newAppDevelopment" });

export default class newApp extends Component {
    render() {
        var sliderSettings = {
            className: "center",
            centerMode:true,
            dots: true,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: false,
            speed: 2000,
            autoplaySpeed: 3000,
            cssEase: "linear",
            centerPadding: "200px",
            arrows: false,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };
        return (
          <div>
            <div className='rootAppN'>
                 <img className="rocketim" src={require("../images/roket-min.png")} alt="rocket"/> 
                  <Helmet>
                    <title>App Developement</title>
                    <meta name="description"
       content="Welcome to Westvindh Games. Creator of Pilli Go and more upcoming futuristic games. Explore the whole new experience of mobile gaming. Join us now."/>
                </Helmet>
      <div className="topApp">
          <div className="upApp">
        <div className="AppHeading1">
       <h1 className="apph1">APP</h1>
    <h1 className="apph2">DEVELOPMENT</h1>  
         </div> 
         <div className="brainImages">
         <img className="mainbrain" src={require("../images/mainbrain.png")} alt="brain"/>
         <img className="flame1N light" src={require("../images/1 light.png")} alt="flame1"/>
         <img className="flame2N light" src={require("../images/1 light.png")} alt="flame2"/>
         <img className="flame3N light" src={require("../images/3rd light.png")} alt="flame3"/>

         <img className="gearN" src={require("../images/gear.png")} alt="gear"/>
         </div>  
         </div>
         <div className="rocketblock">
             <h1 className="rockettxt"> ROCKET YOUR BUSINESS</h1>
             {/* <img className="rocketim" src={require("../images/roket-min.png")} alt="rocket"/> */}     
         </div>
         </div>
            <div className="bottomApp">
             <div>
               </div>
               <div className="carouselRoot">
               <Carousel 
            showArrows={false} 
            infiniteLoop={true}  
            swipeable={true} 
            centerMode={true} 
            emulateTouch={true}
            centerSlidePercentage={50}
            thumbWidth={0}
            showThumbs={false}
            showStatus={false}
            dynamicHeight={false}
            autoPlay={true}
            interval={4000}
            transitionTime={1000}
            
            >

                <div className="wrapElement">
                <div className="SliderElementN">
               
               <div style={{width:"50%",
               color:"white",
               marginLeft:"2%",
               fontFamily:"Humanist777",
               textAlign:"left",
               fontSize:"1.5vw"

            }}  >
                <h1 style={{
                margin:"0"
            }} >ARTIFICIAL</h1>
              <h1 style={{
                margin:"-3% 0 0",
            }} >INTELLIGENCE</h1>
           <p style={{
                marginTop:"20%",
                lineHeight:"1.5"
            }}
           >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
            </div>
                <img style={{width:"56%",
                            height:"20%",

                            marginLeft:"-2%",
                            marginRight:"-8%",
                            marginTop:"-10%",
                            marginBottom:"-12%",
                    
            }} className="bgcardImgN first"src={require('../images/bg1.png')} alt="card"/>
                </div>
                </div>
                <div className="wrapElement">

                <div className="SliderElementN">
                {/* <img className="bluebg"src={require('../images/big box for sliding-min.png')} /> */}
                <div style={{width:"50%",
               color:"white",
               marginLeft:"2%",
               fontFamily:"Humanist777",
               textAlign:"left",
               fontSize:"1.5vw"
            }}  ><h1  style={{
                margin:"0",
           }}> CLOUD BASED </h1>
                <h1 style={{
                 margin:"-3% 0 0",
            }} >INFRASTRUCTURE</h1>
           <p style={{
                marginTop:"20%",
                lineHeight:"1.5"
            }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
            </div>
                <img style={{width:"62%",
                            height:"20%",
                
                            // marginLeft:"5%",
                            marginTop:"-9%",
                            marginBottom:"-9.5%",
                            marginLeft:"-6%"
                    
            }}className="bgcardImgN second"src={require('../images/bg2.png')} alt="card"/>
                </div>
                </div>
                <div className="wrapElement">

                <div className="SliderElementN">
                
                {/* <img className="bluebg"src={require('../images/big box for sliding-min.png')} /> */}
                <div style={{width:"50%",
               color:"white",
               marginLeft:"2%",
               fontFamily:"Humanist777",
               textAlign:"left",
               fontSize:"1.5vw"
            }}  ><h1  style={{
                margin:"0",
           }}>MACHINE</h1>
                <h1 style={{
                 margin:"-3% 0 0",
            }} >LEARNING</h1>
           <p style={{
                marginTop:"20%",
                lineHeight:"1.5",
                marginBottom:"7%"
            }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
            </div>
                <img style={{width:"50%",
                            height:"20%",
                            margin:"-3.05% -4.3% 1% 2%",
                    
            }}className="bgcardImgN second"src={require('../images/bg3.png')} alt="card"/>
                </div>
                </div>
                </Carousel>

                </div>





                <div className="lowerTextN">
        <h1 style={{margin:"0"}} >DATA, NETWORKS</h1>
        <h1 style={{margin:"0"}}>& INFERENECE</h1>
      
        </div>
        <div className="smallgridN1">
        {/* <div className="cards2-1N">  */}
        <div className="containerN1 containerN" >
        <div className="textN textN1">
          <h1 style={{margin:"0",marginTop: "3%"}}>100% USER</h1>
          <h1 style={{margin:"0"}}>COVERAGE</h1>
          <p style={{paddingTop:"13%", paddingBottom:"2%"}}>All users use iOS or Android today. We will make sure that all your customers get your product.</p>
          {/* <button className="learnmoreN" onClick={{}}>
             LEARN MORE
           </button> */}
          </div>
          {/* <img className="bgimgN" src={require("../images/small box-min.png")} alt="smcard"/>  */}
        </div>
        
        {/* </div> */}
        {/* <div className="cards2-2N">  */}
        <div className="containerN2 containerN">
          {/* <img className="bgimgN" src={require("../images/small box-min.png")} alt="smcard"/>  */}
        
        <div className="textN textN2">
          <h1 style={{margin:"0", marginTop: "3%"}}>NATIVE </h1>
          <h1 style={{margin:"0"}}>LANGUAGES</h1>
          <p style={{paddingTop:"13.5%"}}>Our team is the best to code for native platforms.</p>
          {/* <button className="learnmoreN" onClick={{}}>
             LEARN MORE
           </button> */}
          </div>
          </div>
        {/* </div> */}
        {/* <div className="cards2-3N">  */}
        </div>

        <div className="smallgridN2">

        <div className="containerN3 containerN">
          {/* <img className="bgimgN" src={require("../images/small box-min.png")} alt="smcard"/>  */}
        
        <div className="textN textN3">
          <h1 style={{margin:"0"}}>OUR</h1>
          <h1 style={{margin:"0"}}>ADVANTAGE</h1>
        
          <p style={{paddingTop:"13%"}}>Apart from AI and ML algorithms, we know technologies that other people don't.</p>
          {/* <button className="learnmoreN" onClick={{}}>
             LEARN MORE
           </button>*/}
          </div> 
          </div>
        
         
        <div className="containerN4 containerN">
          {/* <img className="bgimgN" src={require("../images/small box-min.png")} alt="smcard"/>  */}
        <div className="textN textN4">
          <h1 style={{margin:"0"}}>USER</h1>
          <h1 style={{margin:"0"}}>EXPERIENCE</h1>
          <p style={{paddingTop:"13%"}}>Westvindh is a first gaming company. This gives us amazing knowledge of UI/UX to make it easiest for user.</p>
          {/* <button className="learnmoreN" onClick={{}}>
             LEARN MORE
           </button> */}
          </div>
          
        </div>
        
        </div>
        <div className="contactUsN">
         <div className='contactTextN'>
            <h1>GET A FREE CONSULTATION</h1>
            <button className="contactbtN" onClick={e => window.location.href='/ContactUs'} >
             CONTACT 
           </button>
         </div>          
        </div>
        <FooterNew />
        
            </div>
            
            </div>
            {/* <diV className="AppFooter">
            {/* <FooterNew />
            </diV> */}
            </div>
        )
    }

}
